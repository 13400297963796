import {
    getList,
    save,
    update,
    remove,
    modifyExperimentPermissions,
    getResetExperimentTimesList,
    resetExperimentTimesMultiple,
    getTeacherExperimentList,
    updateExperiment,
    getFastResetExperimentTimesList
} from "@/api/exp/ExperimentApi";
import {CommonModel} from "@/model/CommonModel";


/**
 实验model
 */
class ExperimentModel {
    // 获取列表
    static async getList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 新增
    static async save(entity) {
        let [res] = await save(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 修改
    static async update(entity) {
        let [res] = await update(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 删除
    static async remove(idArray) {
        let [res] = await remove(idArray)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 修改实验权限
    static async modifyExperimentPermissions(entity) {
        let [res] = await modifyExperimentPermissions(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }

    // 获取教师下属实验列表
    static async getTeacherExperimentList(userInfo) {
        let [res] = await getTeacherExperimentList({
            schoolid:userInfo.schoolid,
            departmentid:userInfo.departmentid,
        })
        if (res.code === 20000) {
            return res.data
        } else {
            return false
        }
    }

    // 重置实验次数弹窗-获取列表
    static async getResetExperimentTimesList(page, size, query) {
        let params = {
            page: page,
            size: size,
        }
        params = Object.assign(params, query)
        let [data] = await getResetExperimentTimesList(params)
        if (size === 0) {  // 不分页
            return data.data;
        } else { // 分页
            let list = CommonModel.generateListMybatisPlus(data.data)
            // 遍历config转换为对象
            let listData = list[0]
            return [listData, list[1]];
        }
    }

    // 快速重置实验次数弹窗-搜索列表
    static async getFastResetExperimentTimesList(query) {
        let params = Object.assign(query)
        let [data] = await getFastResetExperimentTimesList(params)
        return data.data;
    }

    //  重置实验次数弹窗-批量重置
    static async resetExperimentTimesMultiple(ids, experimentnum) {
        let [res] = await resetExperimentTimesMultiple({
            ids: ids,
            experimentnum
        })
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }


    // 修改-实验信息
    static async updateExperiment(entity) {
        let [res] = await updateExperiment(entity)
        if (res.code === 20000) {
            return true
        } else {
            return false
        }
    }
}

export {ExperimentModel}
