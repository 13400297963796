import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_ADMIN, API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表-实验安排
export async function getList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/list`, "get", params);
}

// 新增-实验安排
export async function save(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/save`, "post_json", params);
}

// 修改-实验安排
export async function update(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/update`, "post_json", params);
}

// 删除-实验安排
export async function remove(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/delete`, "post_json", params);
}

// 获取列表-教师下属实验
export async function getTeacherExperimentList(params) {
  return request_async(API_URL_EXP_ADMIN + `admin/schoolschedule/detailsByTeacher`, "post_json", params);
}

// 设置实验权限-实验安排
export async function modifyExperimentPermissions(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/modifyExperimentPermissions`, "post_json", params);
}

// 重置实验次数-获取列表
export async function getResetExperimentTimesList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/list/student`, "get", params);
}

// 快速重置实验次数-获取列表
export async function getFastResetExperimentTimesList(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentschedule/list/fastResetList`, "get", params);
}

// 重置实验次数-弹出-批量修改
/**
 *
 * @param params {{ids:Array,experimentnum}}
 */
export async function resetExperimentTimesMultiple(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentexperiment/resetExperimentNum`, "post_json", params);
}

// 修改-实验安排
export async function updateExperiment(params) {
  return request_async(API_URL_EXP_CONSUMER + `consumer/studentexperiment/update`, "post_json", params);
}
